@import "elements/accordion.css";
@import "elements/carousel.css";
/* @import "elements/dialog.css"; */

:any-link {
  color: var(--color-secondary-pair);
}

svg[class^="icon-"], svg[class*=" icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.sticky {
  position: relative;
  width: 100%;
}

@supports(position:sticky){
  .sticky {
    position: sticky;
  }

  .sticky--top {
    top: 0;
  }
}

.brand-logo {
  display: block;
}

.panel {

  & .container {
    padding-top: var(--ms7);
    padding-bottom: var(--ms7);
  }
}

.panel--primary {
  color: var(--color-white);
  background-color: var(--color-primary);

  & :--h2 {
    color: inherit;
  }
}

.panel--secondary {
  background-color: var(--color-secondary);
}

a[href*="youtube.com"] {
  display: block;
  position: relative;
  max-width: 100%;
  height: 0;
  padding-bottom: calc(100% / var(--video-ratio));

  & > img, & > iframe {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
