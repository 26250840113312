.menu__item {
  display: inline-block;
  vertical-align: middle;

  & :any-link, & select {
    display: block;
    padding: 1em;
  }
}

.menu--primary {
  padding: 0;
  margin: 0;
  text-align: right;

  & .menu__item {
    & :any-link {
      text-transform: uppercase;
      color: var(--color-primary);
      font-weight: var(--font-weight-medium);
    }

    & > ul {
      /* Secondary nav */
      position: absolute;
      display: none;
    }

    &.active {
      & :any-link {
        color: var(--color-secondary);
      }
    }
  }

  & .search {
    & [name="q"] {
      border: none;
    }

    & label {
      /* Place the label on top of the submit button so it will take
      the click to focus on the search input. */
      position: absolute;
      right: 0;
      z-index: 1;
      opacity: 0;
    }
  }
}
