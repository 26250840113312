img.green-backdrop-top-right {
  margin-top: 12px;
  box-shadow: 12px -12px 0px 0px var(--color-secondary);
}

img.blue-backdrop-top-right {
  margin-top: 12px;
  box-shadow: 12px -12px 0px 0px var(--color-primary);
}

img.green-backdrop-bottom-left {
  margin-bottom: 12px;
  box-shadow: -12px 12px 0px 0px var(--color-secondary);
}

img.blue-backdrop-bottom-left {
  margin-bottom: 12px;
  box-shadow: -12px 12px 0px 0px var(--color-primary);
}

a.button {
  font-family: var(--font-family-sans-serif);
  font-weight: var(--font-weight-medium);
  font-size: 1.1em;
  text-transform: none;
  background-color: var(--color-secondary-pair);
  border: none;
  padding: 0.9em 3em;

  &:hover {
    background-color: var(--color-tertiary);
    color: var(--color-primary);
  }
}

a.cta-link {
  font-weight: var(--font-weight-medium);

  &:after {
    font-family: "fontello";
    content: "\e802";
    margin-left: 0.5em;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &:after {
      margin-left: 1em;
    }
  }
}

p.title {
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: 0;
}

img.h3-margin-top {
  margin-top: 5em;

  @media (--md-max) {
    margin-top: 0;
  }
}