.menu--primary {
  & .menu__item {
    & :any-link {
      &:--enter {
        color: var(--color-secondary);
      }
    }

    & > ul {
      /* Secondary menu */
      list-style-type: none;
      text-align: left;
      padding: 0;
    }

    &:--enter {
      & > ul {
        display: block;
      }
    }
  }

  & .search {
    position: relative;

    & [name="q"] {
      outline: 0;
      max-width: 0;
      transition: max-width 0.2s ease;
    }

    & label, & [type="submit"] {
      /* These two should look the same. */
      border: none;
      padding: 0.5em;
      font-size: 1em;
      cursor: pointer;
    }

    & [name="q"]:focus {
      /* Expand the input. */
      max-width: 20em;
    }

    & [name="q"]:focus, & [type="submit"]:focus {
      /* Hide the label when either inputs have focus. */
      & ~ label {
        display: none;
      }
    }
  }
}

.menu--breadcrumbs {
  & .menu__item {
    position: relative;

    & + .menu__item {
      &:before {
        content: "\00BB";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}
