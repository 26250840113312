@media screen {
  .print-only {
    display: none !important;
  }
}

@media print {
  @page {
    size: letter;
    margin: 0.4in;
  }

  .page-header,
  .page-footer,
  .nav--breadcrumbs,
  aside, .aside,
  .no-print,
  #SAMAdminTools,
  #SAMSpacer {
    display: none;
  }

  .container {
    max-width: none;
  }
}
