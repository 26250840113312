.menu--primary {
  & .menu__item {

    & :any-link {
      font-family: var(--font-family-sans-serif);
      font-weight: var(--font-weight-medium);
      color: var(--color-tertiary-pair);
      font-size: 1em;
      line-height: 1em;
      text-transform: none;
      padding-left: 0;
      padding-right: 0;
      margin-left: 1em;
      margin-right: 1em;
      position: relative;

      &:after {
        content: '';
        height: 2px;
        width: 0%;
        background-color: white;
        display: block;
        position: absolute;
        bottom: 0.75em;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        color: white;

        &:after {
          width: 100%;
        }
      }
    }

    &.active :any-link {
      color: white;

      &:after {
        width: 100%;
      }
    }
  }
}

.menu--terms {
  & .menu__item {

    & a {
      padding-top: 0;
      padding-bottom: 0;
    }

    & :any-link {
      position: relative;

      &:after {
        content: '';
        height: 2px;
        width: 0%;
        background-color: white;
        display: block;
        position: absolute;
        bottom: -0.25em;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        color: white;

        &:after {
          width: 100%;
        }
      }
    }
  }
}

nav.nav--breadcrumb {
  position: absolute;
  top: -1em;

  /* & a:not(.button):not(.cta-link) {
    background-position-x: -60%;

    &:hover {
      background-position-x: 150%;
    }
  } */

  & .spacer {
    margin-left: 0.3em;
  }

  & ol {
    padding: 0;
    margin: 0;

    & li {
      list-style: none;
      display: inline-block;
    }
  }

  @media (--sm-max) {
    position: relative;
    top: auto;
    margin-bottom: 1em;
  }
}

.top-anchor {
  position: absolute;
  top: 1500px;
}

a.top-sticky {
  position: fixed;
  right: 1em;
  bottom: 1em;
  background-color: var(--color-black);
  color: white;
  padding: 1em 1.2em 1em 0.5em;
  text-decoration: none;
  background-image: none;
  z-index: 1;

  &:hover {
    & span {
      &:before {
        transform: translateY(-15px);
      }
    }
  }

  & span {
    &:before {
      transition: all 0.3s ease-in-out;
    }
  }

  @media (--sm-max) {
    display: none;
  }
}