.inline.gray-background {
  background-color: var(--color-lightest-gray);
}

.inline {
  padding-top: 2em;
}

.marquee + .inline {
  padding-top: 0;
}