.home {
  & .marquee {
    min-height: 720px;

    & figure.image {
      min-height: 720px;
    }
  }

  @media (--sm-max) {
    & .marquee {
      min-height: none;

      &.sticky {
        position: relative;
      }

      & figure.image {
        min-height: none;
      }
    }
  }
}

.marquee {
  z-index: 0;
  margin-top: -4.7em;
  max-height: 80vh;
  overflow: hidden;

  &.sticky {
    position: sticky;
    top: 0;
  }

  &.editing {
    z-index: 9000;
  }

  & figure.image {
    width: 100%;
    max-height: 80vh;
    margin: 0;
    position: relative;

    & figcaption {
      position: absolute;
      top: 50%;
      right: 50%;
      width: 600px;
      transform: translateY(-50%);
      padding-left: 1em;

      @media (--lg-max) {
        width: 50%;
        padding-left: 1em;
      }

      & h1 {
        margin-top: 0;
      }

      & h1, & p {
        color: white;
      }
    }
  }

  & img {
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 110px;
    top: 0;
    left: 0;
    background-image: url("/images/top-gradient.svg");
    /* background-image: linear-gradient(to top, transparent 0%, var(--color-black) 100%); */
  }

  /* &:after {
    content: '';
    background-image: url("/images/marquee-wave.svg");
    width: 100%;
    height: 0;
    position: absolute;
    bottom: -1px;
    left: 0;
    background-repeat: no-repeat;
    padding-top: 6%;
  } */

  & + section, & + article {
    overflow: visible;
    position: relative;
    background-color: white;

    &:before {
      content: '';
      background-image: url("/images/marquee-wave-new.svg");
      width: 100%;
      height: 0;
      position: absolute;
      /* bottom: 97%; */
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      padding-top: 8.5%;
      transform: translateY(-96%);
    }
  }

  @media (--md-max) {
    margin-top: 0;

    &:before {
      content: none;
    }

    & figure.image figcaption {
      position: relative;
      top: auto;
      right: auto;
      width: auto;
      transform: none;
      padding: 3em 1em;
      max-width: 450px;

      & h1 {
        margin: 0;
        padding-bottom: 0.8em;
      }

      & p {
        margin: 0;
        padding-bottom: 2em;
      }
    }

    & img {
      position: absolute;
      top: 0;
      /* bottom: 0; */
      left: 0;
      /* transform: translateX(-50%); */
      height: 100%;
      display: block;
      object-fit: cover;
      width: auto;
      margin: 0;
      max-width: none;
    }
  }
}


body:not(.home) {
  @media (--md-max) {
    & section.marquee {
      min-height: 330px;

      & img {
        left: auto;
        right: 0;
      }

      & + section {
        &:before {
          bottom: 99.5%;
        }
      }
    }
  }
}