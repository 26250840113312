.page-header {
  & .informations {
    padding: 1em;
    color: var(--color-white);
    background-color: var(--color-primary);
    text-align: center;

    & :any-link {
      color: inherit;
    }
  }
}
