header.page-header {
  background-color: var(--color-primary);

  &.has-marquee {
    background-color: transparent;
  }

  & .button--menu {
    display: none;
  }

  & .brand-logo img {
    width: 100%;
  }

  @media (--md-max) {
    & .brand-logo img {
      max-width: 180px;
    }

    &.page-header {
      top: 0;
      position: sticky;

      & .container {
        padding-left: 0;
        padding-right: 0;

        & .grid {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    & .container .grid {
      text-align: justify;
      justify-content: space-between;

      & > div {
        text-align-last: left;
        text-align-last: start;
        width: 50%;
      }

      & > nav {
        text-align-last: left;
        text-align-last: start;
        width: auto;
        text-align: left;
        padding: 0;
      }
    }

    & nav.nav--primary {
      position: static;
    }

    & nav button.button--menu {
      font-family: var(--font-family-sans-serif);
      font-weight: 500;
      background-color: var(--color-secondary-pair);

      & .button__close {
        display: none;
      }

      &[aria-expanded^="true"] {
        & .button__menu {
          display: none;
        }
        & .button__close {
          display: inline-block;
        }
      }
    }

    & ul.menu--primary {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      width: 100%;
      max-height: 0;
      transition: all 0.3s ease-in-out;
      overflow: hidden;


      &.active {
        max-height: 1000px;
        padding: 2em 1em;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
      }

      & .menu__item {
        display: block;
      }

      & .menu__item.active :any-link {
        color: var(--color-secondary);

        &:after {
          background-color: var(--color-secondary);
          /* content: none; */
        }
      }

      & .menu__item :any-link {
        color: var(--color-secondary);
        text-align: left;

        &:after {
          transition-duration: 0.5s;
          content: none;
        }

        &:hover {
          color: var(--color-secondary);

          &:after {
            background-color: var(--color-secondary);
          }
        }
      }
    }

    &.has-marquee {
      background-color: var(--color-primary);
    }

    & .button--menu {
      display: inline-block;
    }
  }
}