details {
  & summary {
    user-select: none;
    cursor: pointer;
    color: var(--color-secondary);

    /* To align the arrow if needed */
    display: flex;
    justify-content: space-between;

    /* Hide browser's arrow */
    &::-webkit-details-marker {
      display: none;
    }
    &::-moz-list-bullet {
      list-style-type: none;
    }

    & span, &:after {
      font-size: var(--ms2);
      font-weight: var(--font-weight-regular);
    }
  }

  & summary, & .accordion-panel {
    padding: 2em;
  }

  & .accordion-panel {
    border-top-style: solid;
    border-top-width: 1px;


  }

  & + details {
    margin-top: 1em;
  }
}
