:root {
  --font-size-base: 1.125rem;

  --font-family-sans-serif: 'Montserrat', sans-serif;
  --font-family-serif: 'Source Serif Pro', serif;

  /* Color palette */
  --color-primary: #0B409C;
  --color-secondary: #00A843;
  --color-secondary-pair: #008034;
  --color-tertiary: #FFE867;
  --color-tertiary-pair: #FFF9DB;
  --color-white: #ffffff;
  --color-black: #333333;
  --color-gray: #939393;
  --color-light-gray: #C6C6C6;
  --color-lightest-gray: #F4F4F4;
}