:--button {
  display: inline-block;
  font-size: 1em;
  font-weight: var(--font-weight-medium);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: var(--button-padding, 1em);
  border: var(--button-border-width) solid var(--color-primary);
  border-radius: var(--button-border-radius, 0px);
  text-transform: var(--button-text-transform, none);

  color: var(--color-white);
  background-color: var(--color-primary);

  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:--enter {
    background-color: var(--color-secondary);
    color: var(--color-white);
  }
}

.button--secondary {
  color: var(--color-primary);
  background-color: var(--color-white);
}

.centered {
  text-align: center;
}

img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
