:root {
  --font-size-base: 1rem;
  --line-height-base: 1.4;

  /* Color palette */
  --color-primary: #6200EE;
  --color-secondary: #03DAC6;
  --color-white: #ffffff;
  --color-black: #000000;
}
