ul.articles {
  width: 100%;
  list-style: none;
  margin: 0;

  & li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }

  & .article {
    margin-bottom: 3em;
  }

  & .article__image {
    & img {
      width: 100%;
      height: auto;
    }
  }

  & .article__content {
    display: flex;
    flex-wrap: wrap;

    & .article__meta {
      order: 1;
    }

    & .article__title {
      order: 2;
      width: 100%;
    }

    & .article__link {
      order: 3;
    }
  }

}

.article {
  & .article__date {
    border-right: 2px solid var(--color-secondary);
    margin-right: 0.5em;
    padding-right: 0.5em;
  }
}