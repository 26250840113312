.page-footer {
  color: var(--color-white);
  background-color: var(--color-primary);

  & .mce-content-body {
    background-color: color-mod(var(--color-primary) alpha(50%))
  }

  & .container {
    padding: var(--ms5) var(--ms3);
  }
}
