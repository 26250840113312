.full-width {
  overflow: visible;
  /* padding-bottom: 17em;

  & + section {
    margin-top: -17em;
    background: transparent;
  } */

  & .big-wave {
    position: relative;
    padding-bottom: 5em;
    padding-top: 5em;

    &:before {
      content: '';
      background-image: url("/images/big-wave.svg");
      width: 120%;
      height: 0;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-43%, -50%);
      background-repeat: no-repeat;
      z-index: 1;
      padding-top: 65%;
    }

    & img {
      position: relative;
      z-index: 10;
    }
  }

  & img {
    width: 100%;
  }
}