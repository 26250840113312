:root {
  --carousel-control-width: 15%;
  --carousel-indicator-spacer: 3px;
}

.carousel {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.carousel__inner {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  order: -1;
}

.carousel__viewport {
  margin: 0;
  padding: 0;
  transition: transform .6s ease;
  will-change: transform;

  @supports ((--index: 0)) {
    transform: translateX(calc(-100% * var(--index, 0)));
  }
}

.carousel__item {
  white-space: normal;
  display: inline-block;
  width: 100%;

  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel__indicators {
  padding-left: 0;
  margin-right: var(--carousel-control-width);
  margin-left: var(--carousel-control-width);
  list-style: none;
  text-align: center;
  font-size: 20px;

  & li {
    display: inline-block;
    margin-right: var(--carousel-indicator-spacer);
    margin-left: var(--carousel-indicator-spacer);
    text-indent: -999px;
    cursor: pointer;
    outline: 0;
  }

  & a {
    color: transparent;
  }

  & .active {
    color: var(--color-primary);
  }

  & svg {
    display: block;
    pointer-events: none;
    stroke: var(--color-primary);
    transition: all 0.6s ease;
  }
}

.carousel__control--next, .carousel__control--prev {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15%;
  cursor: pointer;
  text-align: center;
  font-size: 2em;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  & * {
    pointer-events: none;
  }
}

.carousel__control--prev {
  left: 0;
}

.carousel__control--next {
  right: 0;
}
