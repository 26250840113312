/* source-serif-pro-regular - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Serif Pro'), local('SourceSerifPro-Regular'),
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-regular.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}
/* source-serif-pro-600 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Source Serif Pro Semibold'), local('SourceSerifPro-Semibold'),
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-600.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}
/* source-serif-pro-700 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'),
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-serif-pro-v5-latin/source-serif-pro-v5-latin-700.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}









/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-300italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v12-latin/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}