:--h1 {
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-regular);
  color: var(--color-primary);
  line-height: 1.2em;

  @media (width < 1070px) {
    font-size: var(--ms4);
    line-height: 1.1em;
  }
}

.inline :--h1 {
  margin-top: 0.4em;
}

:--h2 {
  font-size: var(--ms4);
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-primary);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -0.5em;
    top: 0.2em;
    color: var(--color-secondary);
    font-weight: var(--font-weight-regular);
    background-image: url("/images/pipe.svg");
    height: 0.9em;
    width: 6px;
    background-repeat: no-repeat;
    /* transform: translateY(-50%); */
  }

  @media (--md-max) {
    font-size: var(--ms3);
  }
}

:--h3 {
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-primary);
}

:--h3.small-header {
  font-size: var(--ms0);
  font-family: var(--font-family-sans-serif);
  font-weight: var(--font-weight-medium);
  margin: 0;
}

.card :--h3 {
  font-size: var(--ms0);
  font-family: var(--font-family-sans-serif);
  font-weight: var(--font-weight-medium);
  margin: 0;
}

/* a[target^="blank"]:hover {
  cursor: alias;
} */

p {
  font-family: var(--font-family-sans-serif);
  font-weight: var(--font-weight-light);
  color: var(--color-black);
  margin-bottom: 2em;
}

hr {
  border: none;
  background-color: var(--color-light-gray);
  height: 2px;
}

.page-content {

  & a:not(.button,.cta-link) {
    position: relative;
    display: inline;
    color: var(--color-secondary-pair);
    background-image: linear-gradient(to right, currentColor 0%, currentColor 50%, transparent 50%, transparent 100%);
    background-size: 300% 2px /* The underline has twice the width of text; */;
    background-position-y: 100%;
    background-position-x: -150%;
    background-repeat: repeat-x;
    transition: background-position-x 0s;
    padding-bottom: 2px;
    transition-duration: 0.5s;

    &:hover, &:focus, &.hovered {
      box-shadow: none;
      background-position-x: -60%;
    }
  }


  & ul {
    padding: 0;

    & li {
      list-style: none;
      padding-left: 0.5em;
      padding-bottom: 0.3em;

      &:before {
        position: relative;
        left: -0.5em;
        content: '-';
        color: var(--color-primary);
      }
    }
  }
}