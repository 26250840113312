.form__group:not([role="group"]) {
  /* For normal input group, we have the label after the input so that
  we can target the label based on the input's state. However, we want
  to visually see the label above the input. */
  display: table;
  width: 100%;

  & .form__label {
    display: table-header-group; /* Move the label to the top */
  }
}

@supports (display: flex) {
  .form__group:not([role="group"]) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.form__control {
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em;
}

.form__label {
  vertical-align: middle;
  margin-bottom: 0.5em;
}

.radio, .checkbox {
  & .form__label {
    margin-bottom: 0;
  }
}

input[type="radio"],
input[type="checkbox"] {
  vertical-align: middle;

  & + .form__label {
    margin-left: 0.5em;
  }
}
