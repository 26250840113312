.people {
  background-color: var(--color-lightest-gray);
}

.person {
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background-color: white;
  transition: box-shadow 0.2s ease-in-out;

  & p:last-child {
    margin-bottom: 0;
  }

  & .person__content {
    padding: 2em;

    & .person__name {
      font-size: var(--ms0);
      font-family: var(--font-family-sans-serif);
      font-weight: var(--font-weight-medium);
      color: var(--color-black);
      margin-bottom: 0;
    }

    & .person__title {

    }
  }

  & .person__image {
    & img {
      width: 100%;
      height: auto;
    }
  }

  & .person__detail {
    display: none;
  }

  &.hoverable:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.7);
    outline: 1px solid var(--color-secondary);

    & a.cta-link:after {
      margin-left: 1em;
    }
  }
}

.SAMEditing {
  float: none;
  width: 100%;
  clear: both;

  & .person {

    background-color: rgba(255,255,255,0.2);

    & img {
      width: auto;
      opacity: 0.5;
    }

    & .person__content {
      display: none;
    }

    & + div {
      clear: both;
    }
  }

  & .person__detail {
    display: block;
    padding: 0;
  }
}