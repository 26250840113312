footer.page-footer {
  background-color: var(--color-black);
  position: relative;
  z-index: 9;
  overflow: hidden;

  & .back-to-top {
    text-align: center;

    & a {
      color: white;

      @media (--sm-max) {
        font-size: 20px;
        padding: 0.5em;
        display: inline-block;
      }

      &:hover {
        & span {
          &:before {
            transform: translateY(-15px);
          }
        }
      }

      & span {
        &:before {
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  & .legal {
    & nav {
      display: inline-block;
    }

    & .back-to-top {
      float: right;

      @media (--sm-max) {
        display: none;
      }
    }
  }

  & .container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 25px;
    padding-right: 25px;
  }

  & hr {
    margin: 0;
    color: var(--color-light-gray);
  }

  & .menu--primary {
    text-align: left;

    & li:first-child {
      & a {
        margin-left: 0;
      }
    }
  }

  & .nav--legal {
    & ul {
      margin: 0;
      padding: 0;

      & li {
        display: inline-block;
        vertical-align: middle;

        border-right: solid white 1px;

        &:last-child {
          border: none;
        }

        & a {
          font-family: var(--font-family-sans-serif);
          font-weight: var(--font-weight-medium);
          color: var(--color-lightest-gray);
          font-size: 0.8em;
          line-height: 1em;
          text-transform: none;
          margin-left: 1em;
          margin-right: 1em;
          padding: 0;

          &:hover {
            color: white
          }
        }

        &:first-child {
          & a {
            margin-left: 0;
          }
        }
      }
    }
  }


  @media (--sm-max) {
      & .menu__item :any-link {
        &:after {
          content: none;
        }
      }
  }

  @media (--sm-max) {

    & .container {
      padding: 0;
    }

    & .legal {
      padding-left: 1.5em;
    }

    & .nav--primary {
      & ul {
        columns: 2 auto;
        vertical-align: top;

        & li {
          display: block;
          vertical-align: top;

          &:first-child {
            & a {
              margin-left: 1em;
            }
          }

          & a {
            &:after {
              display: none;
            }
          }

          &.active {
            & a {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}