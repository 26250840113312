.modal {
  display: none;
}

.vanilla-modal {
  & .modal {
    display: block;
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s, z-index 0s 0.2s;
    text-align: center;
    overflow: hidden;
    overflow-y: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    & > * {
      display: inline-block;
      white-space: normal;
      vertical-align: middle;
      text-align: left;
    }

    &:before {
      display: inline-block;
      overflow: hidden;
      width: 0;
      height: 100%;
      vertical-align: middle;
      content: "";
    }

    &.outer-close {
      .modal-inner {
        overflow: visible;
      }
    }
  }
}

.modal-inner {
  position: relative;
  overflow: hidden;
  max-width: 650px;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  z-index: -1;
  opacity: 0;
  box-shadow: 0 0 2em fadeout(black, 50%);
  transform: scale(0);
  transition: opacity 0.2s, transform 0.2s, z-index 0s 0.2s;

  & .icon-close {
    position: absolute;
    right: 0;
    font-size: 2em;
    color: var(--color-secondary-pair);
    cursor: pointer;
  }

  & .modal-content {
    padding: 3em;
  }
}

.modal-visible {
  & .modal {
    z-index: 99;
    opacity: 1;
    transition: opacity 0.2s;
  }

  & .modal-inner {
    z-index: 100;
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.2s, transform 0.2s;
  }
}