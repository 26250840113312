/*
 * Enable automatic content scaling across various window dimensions in IE 10
 */
 @-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/*
 * 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
 * 2. Change the default font family in all browsers.
 * 3. Correct the line height in all browsers.
 */
html {
  box-sizing: border-box; /* 1 */
  font-family: sans-serif; /* 2 */
  line-height: 1.15; /* 3 */
}
*, *:before, *:after {
  box-sizing: inherit;
}

/*
 * 1. Remove the margin in all browsers.
 * 2. Set an explicit initial text-align value so that we can later use the
 *    the `inherit` value on things like `<th>` elements.
 */
body {
  margin: 0; /* 1 */
  font-family: system-ui;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
  text-align: left; /* 2 */
}

/*
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 *
 * Credit: https://github.com/suitcss/base
*/
[tabindex="-1"]:focus {
  outline: 0 !important;
}

a {
  text-decoration: none; /* Most of the time links don't have underline */
  background-color: transparent; /* Remove the gray background on active links in IE 10 */
}
/*
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
} */
