/* Custom properties. Examples: https://googlechrome.github.io/samples/css-custom-properties/*/
:root {
  /* :root is the same as html but with a higher specificity. */

  /* Breakpoints */
  --breakpoint-xs: 576px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1600px;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 900;

  /* Button properties */
  --button-padding: 1em;
  --button-border-width: 1px;
  --button-border-radius: 2px;
  --button-text-transform: uppercase;

  /* Modular scale settings */
  --ms-base: 1em;
	--ms-ratio: var(--major-third);

  --video-ratio: calc(16 / 9); /* Widescreen */
}

/* Custom selectors */
@custom-selector :--h1 h1, .h1;
@custom-selector :--h2 h2, .h2;
@custom-selector :--h3 h3, .h3;
@custom-selector :--h4 h4, .h4;
@custom-selector :--h5 h5, .h5;
@custom-selector :--h6 h6, .h6;
@custom-selector :--heading h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6;
@custom-selector :--button .button, .form-container button[type="submit"];
@custom-selector :--input input, textarea, select;
@custom-selector :--enter :hover, :focus, .enter;

/*
Custom media
Usage: @media (--lg) {}
*/
@custom-media --sm (width >= 576px);
@custom-media --xs-max (width < 576px);
@custom-media --md (width >= 768px);
@custom-media --sm-max (width < 768px);
@custom-media --lg (width >= 992px);
@custom-media --md-max (width < 992px);
@custom-media --xl (width >= 1200px);
@custom-media --lg-max (width < 1200px);
