
@import "css-modularscale"; /* == @import "../node_modules/css-modularscale/index.css"; */
@import "express/properties.css";
@import "../node_modules/reflex-grid/css/reflex.css";
@import "../node_modules/dialog-polyfill/dialog-polyfill.css";
@import "critical.css";
@import "express/header.css";
@import "express/menus.css";
@import "express/typography.css";
@import "express/elements.css";
@import "express/forms.css";
@import "express/user.css";
@import "express/footer.css";
@import "express/print.css";

html {
  scroll-behavior: smooth;
}
