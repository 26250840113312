/* @import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,500,600|Source+Serif+Pro:400,600,700'); */

@import "express.css";
@import "rigby/fonts.css";
@import "rigby/properties.css";
@import "rigby/typography.css";
@import "rigby/header.css";
@import "rigby/navigation.css";
@import "rigby/footer.css";
@import "rigby/marquee.css";
@import "rigby/columns.css";
@import "rigby/classes.css";
@import "rigby/full-width.css";
@import "rigby/person.css";
@import "rigby/modal.css";
@import "rigby/news.css";
@import "rigby/inline.css";
@import "rigby/helpers.css";
@import "../fonts/fontello/css/fontello.css";
@import "../node_modules/aos/dist/aos.css";

body {
  overflow-x: hidden;
}

/* body main.page-content:not(*:root) {
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;


  & .marquee {
    z-index: 1;
  }

  & > section:not(.marquee) {
    z-index: 2;
  }
} */

body:not(.samadmin) main.page-content {
  header.page-header {
    transform: translateZ(0);
  }

  & > *:not(.marquee):not(.full-width) {
    transform: translateZ(0);
  }
}

/* _::-webkit-full-page-media, _:future, :root main.page-content {
  height: 200px;
  overflow: hidden;
} */

/* @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
  @media {
    body:not(.samadmin) main.page-content {
      & > *:not(.marquee) {
        transform: translateZ(0);
      }
    }
  }
}

@media not all and (min-resolution:.001dpcm) {
  @media {
    body:not(.samadmin) main.page-content {
      & > *:not(.marquee) {
        transform: translateZ(0);
      }
    }
  }
}

_:lang(x)::x-, body:not(.samadmin) main.page-content {
  & > *:not(.marquee) {
    transform: translateZ(0);
  }
} */




/* @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{ @media {

     body:not(.samadmin) main.page-content {
      transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;


      & .marquee {
        z-index: -1;
      }
    }
}} */

main {
  position: relative;
}

section {
  background-color: white;
  position: relative;
  overflow: hidden;
}

h2 {
  position: relative;
}

span.rellax {
  display: inline-block;
}

/* .page-container {
  overflow: hidden;
} */