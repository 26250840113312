.columns {
  padding-bottom: 2em;

  & .row {
    width: 100%;
    position: relative;
  }

  & .card {
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 2em;
    background-color: white;
    height: 100%;
    transition: box-shadow 0.2s ease-in-out;

    & p:last-child {
      margin-bottom: 0;
    }

    & img {
      margin-top: 1em;
      margin-bottom: 3.5em;
    }

    &.hoverable:hover {
      cursor: pointer;
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.7);
      outline: 1px solid var(--color-secondary);

      & a.cta-link:after {
        margin-left: 1em;
      }
    }
  }

  & .row.flat-card {

  }

  & div[class^="col-"] {

    & .flat-card {
      padding: 2em 2em 0 2em;
      height: 100%;
      overflow: hidden;

      & > *:last-child {
        margin-bottom: 0;
      }

      & p:nth-child(2) {
        position: relative;

        &:after {
          content: "";
          height: 500px;
          width: 2px;
          background-color: var(--color-light-gray);
          position: absolute;
          right: -2em;
          top: 0;
        }

        @media (--md-max) {
          &:after {
            display: none;
          }
        }
      }

      & img {
        margin-bottom: 3em;
      }
    }

    &:last-child {
      & .flat-card {
        & p:nth-child(2) {

          &:after {
            display: none;
          }
        }
      }
    }
  }

  &.columns--two {
    padding-bottom: 0;
  }

  &.columns--two .grid {
    padding-bottom: 2em;

    &.border-bottom {
      padding-bottom: 3em;
      margin-bottom: 3em;
      border-bottom: 2px solid var(--color-light-gray);
    }

    & div[class^="col-"] {
      & p:last-child {
        margin-bottom: 0;
      }
    }
  }

  /* &.columns--three {
    & .row {
      & div[class^="col-"] {
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }
  } */

  &.columns--three.wave-loop {
    &:before {
      content: '';
      background-image: url("/images/wave-loop.svg");
      width: 685px;
      height: 545px;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      z-index: 0;
    }
  }

  &.columns--three .row:not(.flat-card) {
    overflow: visible;

    /* &:first-child:before, &:last-child:before {
      content: '';
      background-image: url("/images/thin-wave-fade.svg");
      width: 125%;
      height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
      z-index: 0;
      padding-top: 43%;
    }

    &:not(:first-child):last-child:before {
      transform: translate(-50%, -15%);
    } */
  }


  &.columns--two.big-wave {
    position: relative;

    &:before {
      content: '';
      background-image: url("/images/big-wave.svg");
      width: 2000px;
      height: 1100px;
      position: absolute;
      bottom: -34em;
      right: -17em;
      /* transform: translateX(-50%); */
      background-repeat: no-repeat;
      z-index: 0;
    }

    & .grid.big-wave {
      margin-top: 4em;

      @media (--md-max) {
        margin-top: 0;
      }
    }
  }
}