.container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 25px;
  padding-left: 25px;

  & .grid {
    margin-right: -12.5px;
    margin-left: -12.5px;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.align-center {
  align-items: center;
}

/* [class^=col-] {
  padding: 15px;
} */

.col-md-3 {
  width: 25%;
}

.col-md-9 {
  width: 75%;
}
