.mobile-only {
  display: none;
}

@media (--sm-max) {
  .mobile-only {
    display: block;
  }

  div[class^="col-"].mobile-only {
    display: inline-block;
  }
}