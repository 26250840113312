:--h1 {
  font-size: var(--ms5);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--ms-3); /* Going down 3 scale */
}

:--h2 {
  font-size: var(--ms3);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--ms-2); /* Going down 2 scale */
  color: var(--color-primary);

  &:first-child {
    margin-top: 0;
  }
}

:--h3 {
  font-size: var(--ms2);
  font-weight: var(--font-weight-regular);

  &:first-child {
    margin-top: 0;
  }
}

:--h4 {
  font-size: var(--ms1);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--ms1);

  &:first-child {
    margin-top: 0;
  }
}

p {
  line-height: 1.6;
  margin-top: 0;

  &:only-child {
    margin: 0;
  }
}

sup {
  font-size: var(--ms-3);
  padding-right: 4px;
}
